@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  border: none;
  outline: none;
  scroll-behavior: smooth;
  font-family: 'Poppins', sans-serif;
}

::-webkit-scrollbar {
  width: 5px;
  background: lightgray;
}

::-webkit-scrollbar-track {
  background: var(--bg-color);
}

::-webkit-scrollbar-thumb {
  background: var(--second-bg-color);
}

:root {
  --bg-color: #1f242d;
  --second-bg-color: #323946;
  --text-color: #fff;
  --main-color: #0ef;
}

html {
  font-size: 62.5%;
  overflow-x: hidden;
}

body {
  background: var(--bg-color);
  color: var(--text-color);

}

section {
  min-height: 100vh;
  padding: 10rem 9% 2rem;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 2rem 9%;
  background: var(--bg-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 100;
}

.logo {
  font-size: 2.5rem;
  color: var(--text-color);
  font-weight: 600;
  cursor: default;
}

.navbar a {
  font-size: 1.7rem;
  color: var(--text-color);
  margin-left: 4rem;
  transition: .3s;
}

.navbar a:hover,
.navbar a.active {
  color: var(--main-color);
}

#menu-icon {
  font-size: 3.6rem;
  color: var(--text-color);
  display: none;
}

.home-img {
  filter: drop-shadow(0px 1vw 1vw rgba(0, 238, 255, 0.3));
  width: 35vw;
}

.hexagon {
  position: relative;
  width: 37vw;
  height: 40vw;
  clip-path: polygon(47.5% 5.66987%,
      48.2899% 5.30154%,
      49.13176% 5.07596%,
      50% 5%,
      50.86824% 5.07596%,
      51.7101% 5.30154%,
      52.5% 5.66987%,
      87.14102% 25.66987%,
      87.85495% 26.16978%,
      88.47124% 26.78606%,
      88.97114% 27.5%,
      89.33948% 28.2899%,
      89.56505% 29.13176%,
      89.64102% 30%,
      89.64102% 70%,
      89.56505% 70.86824%,
      89.33948% 71.7101%,
      88.97114% 72.5%,
      88.47124% 73.21394%,
      87.85495% 73.83022%,
      87.14102% 74.33013%,
      52.5% 94.33013%,
      51.7101% 94.69846%,
      50.86824% 94.92404%,
      50% 95%,
      49.13176% 94.92404%,
      48.2899% 94.69846%,
      47.5% 94.33013%,
      12.85898% 74.33013%,
      12.14505% 73.83022%,
      11.52876% 73.21394%,
      11.02886% 72.5%,
      10.66052% 71.7101%,
      10.43495% 70.86824%,
      10.35898% 70%,
      10.35898% 30%,
      10.43495% 29.13176%,
      10.66052% 28.2899%,
      11.02886% 27.5%,
      11.52876% 26.78606%,
      12.14505% 26.16978%,
      12.85898% 25.66987%);
  background: var(--main-color);
  overflow: hidden;
  /* filter: drop-shadow(50px 20px 100px rgba(255, 0, 0, .7)); */
  /* filter: drop-shadow(10 15 10px var(--main-color) / 0.4); */
}


.hexagon img {
  padding-top: 0%;
  padding-left: 0%;
  width: 110%;
  height: auto;
  display: block;
  filter: drop-shadow(10px 5px 7px rgba(0, 0, 0, .5));
}

.hexagon-container {
  display: flex;
  justify-content: center;
  align-items: center;
}


.hexagon-container img {
  padding-top: 10%;
  width: 100%;
  height: auto;
  display: block;
  /* filter: drop-shadow(10px 5px 7px rgba(0, 0, 0, .5)); */
}

.home {
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-content h3 {
  font-size: 3.2rem;
  font-weight: 700;
}

.home-content h3:nth-of-type(2) {
  margin-bottom: 2rem;
}

span {
  color: var(--main-color);
}

.home-content h1 {
  font-size: 5.6rem;
  font-weight: 700;
  line-height: 1.3;
}

.home-content p {
  font-size: 1.6rem;

}

.home-content .social-media a {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 4rem;
  height: 4rem;
  background: transparent;
  border: .2rem solid var(--main-color);
  border-radius: 50%;
  font-size: 2rem;
  color: var(--main-color);
  margin: 3rem 1.5rem 3rem 0;
  transition: .5s ease;
}

.home-content .social-media a:hover {
  background: var(--main-color);
  color: var(--second-bg-color);
  box-shadow: 0 0 1rem var(--main-color);
}

.btn {
  display: inline-block;
  padding: 1rem 2.8rem;
  background: var(--main-color);
  border-radius: 4rem;
  box-shadow: 0 0 1rem var(--main-color);
  font-size: 1.6rem;
  color: var(--second-bg-color);
  letter-spacing: .1rem;
  font-weight: 600;
  transition: .5s ease;
}

.btn:hover {
  box-shadow: none;
}

.hexagon-about {
  width: 26vw;
  height: 30vw;
  clip-path: polygon(47.5% 5.66987%,
      48.2899% 5.30154%,
      49.13176% 5.07596%,
      50% 5%,
      50.86824% 5.07596%,
      51.7101% 5.30154%,
      52.5% 5.66987%,
      87.14102% 25.66987%,
      87.85495% 26.16978%,
      88.47124% 26.78606%,
      88.97114% 27.5%,
      89.33948% 28.2899%,
      89.56505% 29.13176%,
      89.64102% 30%,
      89.64102% 70%,
      89.56505% 70.86824%,
      89.33948% 71.7101%,
      88.97114% 72.5%,
      88.47124% 73.21394%,
      87.85495% 73.83022%,
      87.14102% 74.33013%,
      52.5% 94.33013%,
      51.7101% 94.69846%,
      50.86824% 94.92404%,
      50% 95%,
      49.13176% 94.92404%,
      48.2899% 94.69846%,
      47.5% 94.33013%,
      12.85898% 74.33013%,
      12.14505% 73.83022%,
      11.52876% 73.21394%,
      11.02886% 72.5%,
      10.66052% 71.7101%,
      10.43495% 70.86824%,
      10.35898% 70%,
      10.35898% 30%,
      10.43495% 29.13176%,
      10.66052% 28.2899%,
      11.02886% 27.5%,
      11.52876% 26.78606%,
      12.14505% 26.16978%,
      12.85898% 25.66987%);
  background: var(--bg-color);
  overflow: hidden;
  /* filter: drop-shadow(50px 20px 100px rgba(255, 0, 0, .7)); */
}

.hexagon-about img {
  padding-top: 10%;
  width: 100%;
  height: auto;
  display: block;
  filter: drop-shadow(0 1vw 1vw rgba(0, 0, 0, .5));
}

.about {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  background: var(--second-bg-color);

}

.about-img {
  filter: drop-shadow(0px 1vw 1vw rgba(0, 0, 0, .5));
  width: 35vw;
}

.about-img img {
  /* width: 35vw; */
  padding-top: 2%;
  width: 100%;
  height: auto;
  display: block;
  filter: drop-shadow(10px 5px 7px rgba(0, 0, 0, .5));
}

.heading {
  text-align: center;
  font-size: 4.5rem;
}

.about-content h2 {
  text-align: left;
  line-height: 1.2;
}

.about-content h3 {
  font-size: 2.6rem;

}

.about-content p {
  font-size: 1.6rem;
  margin: 2rem 0 3rem;
}

.services h2 {
  margin-bottom: 5rem;
}

.services-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 2rem;
}

.services-container .services-box {
  flex: 1 1 30rem;
  background: var(--second-bg-color);
  padding: 3rem 2rem 4rem;
  border-radius: 2rem;
  text-align: center;
  border: .2rem solid var(--bg-color);
  transition: .5s ease;
  min-height: 450px;
  /* max-height: 550px; */
  /* display: flex; */
  justify-content: center;
  align-items: center;
}

.services-container .services-box:hover {
  border-color: var(--main-color);
  transform: scale(1.02);
}

.services-box i {
  font-size: 7rem;
  color: var(--main-color);
}

.services-box h3 {
  font-size: 2.6rem;
}

.services-box p {
  font-size: 1.6rem;
  margin: 1rem 0 3rem;
}

.portfolio {
  background: var(--second-bg-color);
}

.portfolio h2 {
  margin-bottom: 4rem;
}

.portfolio-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  gap: 2.5rem;
}

.portfolio-container .portfolio-box {
  position: relative;
  border-radius: 2rem;
  box-shadow: 0 0 1rem var(--bg-color);
  overflow: hidden;
  display: flex;
}

.portfolio-box img {
  width: 100%;
  transition: .5s ease;
}

.portfolio-box:hover img {
  transform: scale(1.1);
}

.portfolio-box .portfolio-layer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(rgba(0, 0, 0, .8), var(--main-color));
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: 0 4rem;
  transform: translateY(100%);
  transition: .5s ease;
}

.portfolio-box:hover .portfolio-layer {
  transform: translateY(0);
}

.portfolio-layer h4 {
  font-size: 3rem;
}

.portfolio-layer p {
  font-size: 1.6rem;
  margin: .3rem 0 1rem;
}

.portfolio-layer a {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 5rem;
  height: 5rem;
  background: var(--text-color);
  border-radius: 50%;
}

.portfolio-layer a i {
  font-size: 2rem;
  color: var(--second-bg-color);
}

.contact h2 {
  margin-bottom: 3rem;
}

.contact form {
  max-width: 70rem;
  margin: 1rem auto;
  text-align: center;
  margin-bottom: 3rem;
}

.contact form .input-box {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.contact form .input-box input,
.contact form textarea {
  width: 100%;
  padding: 1.5rem;
  font-size: 1.6rem;
  color: var(--text-color);
  background: var(--second-bg-color);
  border-radius: .8rem;
  margin: .7rem 0;
}

.contact form .input-box input {
  width: 49%;
}

.contact form textarea {
  resize: none;
}

.contact form btn {
  margin-top: 2.rem;
  cursor: pointer;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 2rem 9%;
  background: var(--second-bg-color);
  margin-top: -5.2vh;
}

.footer-text p {
  font-size: 1.6rem;
}

.footer-iconTop a {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: .8rem;
  background: var(--main-color);
  border-radius: .8rem;
  transition: .5s ease;
}

.footer-iconTop a:hover {
  box-shadow: 0 0 1rem var(--main-color);
}

.footer-iconTop a i {
  font-size: 2.4rem;
  color: var(--second-bg-color);
}

/* BREAKPOINTS */
@media (min-width: 992px) {
  html {
    font-size: 55%;
  }
}

@media (max-width: 991px) {
  .header {
    padding: 2rem 3%;
  }

  section {
    padding: 10rem 3%, 2rem;
  }

  .services {
    padding-bottom: 7rem;
  }

  .portfolio {
    padding-bottom: 7rem;
  }

  .footer {
    padding: 2rem 3%;
  }
}

@media (max-width: 768px) {
  #menu-icon {
    display: block;
  }

  .navbar {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    padding: 1rem 3%;
    background: var(--bg-color);
    border-top: .1rem solid rgba(0, 0, 0, .2);
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .2);
    display: none;
  }

  .navbar.active {
    display: block;
  }

  .navbar a {
    display: block;
    font-size: 2rem;
    margin: 3rem 0;
  }

  .home {
    flex-direction: column;
  }

  .home-content h3 {
    font-size: 2.6rem;
  }

  .home-content h1 {
    font-size: 5rem;
  }

  .hexagon {
    position: relative;
    width: 60vw;
    height: 66vw;
    clip-path: polygon(47.5% 5.66987%,
        48.2899% 5.30154%,
        49.13176% 5.07596%,
        50% 5%,
        50.86824% 5.07596%,
        51.7101% 5.30154%,
        52.5% 5.66987%,
        87.14102% 25.66987%,
        87.85495% 26.16978%,
        88.47124% 26.78606%,
        88.97114% 27.5%,
        89.33948% 28.2899%,
        89.56505% 29.13176%,
        89.64102% 30%,
        89.64102% 70%,
        89.56505% 70.86824%,
        89.33948% 71.7101%,
        88.97114% 72.5%,
        88.47124% 73.21394%,
        87.85495% 73.83022%,
        87.14102% 74.33013%,
        52.5% 94.33013%,
        51.7101% 94.69846%,
        50.86824% 94.92404%,
        50% 95%,
        49.13176% 94.92404%,
        48.2899% 94.69846%,
        47.5% 94.33013%,
        12.85898% 74.33013%,
        12.14505% 73.83022%,
        11.52876% 73.21394%,
        11.02886% 72.5%,
        10.66052% 71.7101%,
        10.43495% 70.86824%,
        10.35898% 70%,
        10.35898% 30%,
        10.43495% 29.13176%,
        10.66052% 28.2899%,
        11.02886% 27.5%,
        11.52876% 26.78606%,
        12.14505% 26.16978%,
        12.85898% 25.66987%);
    background: var(--main-color);
    overflow: hidden;
    /* filter: drop-shadow(50px 20px 100px rgba(255, 0, 0, .7)); */
    /* filter: drop-shadow(10 15 10px var(--main-color) / 0.4); */
  }


  .hexagon img {
    padding-top: 0%;
    padding-left: 0%;
    width: 110%;
    height: auto;
    display: block;
    filter: drop-shadow(10px 5px 7px rgba(0, 0, 0, .5));
  }

  .hexagon-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }


  .hexagon-container img {
    padding-top: 10%;
    width: 100%;
    height: auto;
    display: block;
    /* filter: drop-shadow(10px 5px 7px rgba(0, 0, 0, .5)); */
  }

  .home-img {
    filter: drop-shadow(0px 1vw 1vw rgba(0, 238, 255, 0.3));
    width: 60vw;
  }

  .home-img img {
    /* width: 70vw; */
    margin-top: 0rem;
  }

  .about {
    flex-direction: column;
  }

  .hexagon-about {
    width: 60vw;
    height: 66vw;
    clip-path: polygon(47.5% 5.66987%,
        48.2899% 5.30154%,
        49.13176% 5.07596%,
        50% 5%,
        50.86824% 5.07596%,
        51.7101% 5.30154%,
        52.5% 5.66987%,
        87.14102% 25.66987%,
        87.85495% 26.16978%,
        88.47124% 26.78606%,
        88.97114% 27.5%,
        89.33948% 28.2899%,
        89.56505% 29.13176%,
        89.64102% 30%,
        89.64102% 70%,
        89.56505% 70.86824%,
        89.33948% 71.7101%,
        88.97114% 72.5%,
        88.47124% 73.21394%,
        87.85495% 73.83022%,
        87.14102% 74.33013%,
        52.5% 94.33013%,
        51.7101% 94.69846%,
        50.86824% 94.92404%,
        50% 95%,
        49.13176% 94.92404%,
        48.2899% 94.69846%,
        47.5% 94.33013%,
        12.85898% 74.33013%,
        12.14505% 73.83022%,
        11.52876% 73.21394%,
        11.02886% 72.5%,
        10.66052% 71.7101%,
        10.43495% 70.86824%,
        10.35898% 70%,
        10.35898% 30%,
        10.43495% 29.13176%,
        10.66052% 28.2899%,
        11.02886% 27.5%,
        11.52876% 26.78606%,
        12.14505% 26.16978%,
        12.85898% 25.66987%);
    background: var(--bg-color);
    overflow: hidden;
    /* filter: drop-shadow(50px 20px 100px rgba(255, 0, 0, .7)); */
  }

  .hexagon-about img {
    padding-top: 10%;
    width: 100%;
    height: auto;
    display: block;
    filter: drop-shadow(0 1vw 1vw rgba(0, 0, 0, .5));
  }

  .about {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    background: var(--second-bg-color);

  }

  .about-img {
    filter: drop-shadow(0px 1vw 1vw rgba(0, 0, 0, .5));
    width: 60vw;
  }

  .about-img img {
    /* width: 35vw; */
    padding-top: 2%;
    width: 100%;
    height: auto;
    display: block;
    filter: drop-shadow(10px 5px 7px rgba(0, 0, 0, .5));
  }

  .services h2 {
    margin-bottom: 3rem;
  }

  .portfolio h2 {
    margin-bottom: 3rem;
  }

  .portfolio h4 {
    font-size: 2rem;
  }

  .portfolio-container {
    grid-template-columns: repeat(2, 1fr);
  }

  .footer {
    flex-direction: column-reverse;
  }

  .footer p {
    text-align: center;
    margin-top: 2rem;
  }
}

@media (max-width: 617px) {
  .portfolio-container {
    grid-template-columns: 1fr;
  }

  .footer {
    flex-direction: column-reverse;
  }

  .footer p {
    text-align: center;
    margin-top: 2rem;
  }
}

@media (max-width: 450px) {
  html {
    font-size: 50%;
  }

  .contact form .input-box input {
    width: 100%;
  }

  .footer {
    flex-direction: column-reverse;
  }

  .footer p {
    text-align: center;
    margin-top: 2rem;
  }
}